/* import { Col, Row, Stack } from "react-bootstrap"; */
import { Outlet, Link } from "react-router-dom";
import "./header.css";

function Header() {
  return (
    <div className="row g-0 justify-content-left minHeight">
      <div className="col-2"></div>
      <div className="col-4 d-flex align-items-center ps-3">
        <img
          className="logoImg"
          src="./images/logo/quantum_pulse.png"
          alt="icon"
        />
        <div className="d-flex flex-column hedingMinHeight">
          <div className="d-flex flex-row font-face-BebasNeue position-relative companyNameTop">
            <span className="logHeadingTxt1">QUANTUM</span>
            <span className="logHeadingTxt2"> PULSE</span>
          </div>
          <span className="logHeadingTxt3 font-face-OpenSans">
            Technologies
          </span>
        </div>
        <input
          type="text"
          placeholder="search..."
          className="inpTxt font-face-OpenSans"
        />
      </div>
      <div className="col-5 d-flex justify-content-left align-items-center font-face-OpenSans">
        <span className="menuTxt">
          <Link className={"link-styles"} to="/">
            HOME
          </Link>
        </span>
        <span className="menuSeperator mx-2">|</span>
        <span className="menuTxt">
          <Link className={"link-styles"} to="design">
            DESIGN SERVICES
          </Link>
        </span>
        <span className="menuSeperator mx-2">|</span>
        <span className="menuTxt">
          <Link className={"link-styles"} to="rps">
            RPS
          </Link>
        </span>
        <span className="menuSeperator mx-2">|</span>
        <span className="menuTxt">
          <Link className={"link-styles"} to="infra">
            INFRASTRUCTURE
          </Link>
        </span>
        <span className="menuSeperator mx-2">|</span>
        <span className="menuTxt">
          <Link className={"link-styles"} to="jobs">
            JOBS
          </Link>
        </span>
        <span className="menuSeperator mx-2">|</span>
        <span className="menuTxt">
          <Link className={"link-styles"} to="contact">
            CONTACT US
          </Link>
        </span>
      </div>
    </div>
  );
}

export default Header;
