import "./rps_page_desc.css";

function RpsPageDescription() {
  return (
    <>
      <div className="row g-0 d-flex justify-content-center text-center pageDescBg">
            <span className=" col-7 font-face-OpenSans pageDescTxt py-4">
              At Quantum Pulse Technologies, we specialize in delivering
              world-class Recruitment Process Outsourcing (RPO) solutions and
              Design Services to empower businesses of all sizes. Whether you’re
              looking to streamline your hiring process or require cutting-edge
              design expertise, we combine industry-leading technology with deep
              sector knowledge to help you achieve your organizational goals.
            </span>
      </div>
    </>
  );
}

export default RpsPageDescription;
