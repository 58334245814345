import "./our_expertise.css";

function OurExpertise() {
  return (
    <>
      <div className="row  g-0 my-4">
        <div className="col-12 text-center">
          <div className="ouExpHedTxt font-face-Thasadith"> OUR EXPERTISE</div>
          
          <img
            className="my-2"
            alt="our_expertise"
            src="./images/home/our_expertise.svg"
          ></img>
          <div className="d-flex justify-content-center">
            <span className="descTxt font-face-OpenSans mt-4 pb-3">
              Finding the right talent can be challenging, but with Quantum
              Pulse, it becomes effortless. Our RPO solutions are designed to
              manage your end-to-end recruitment process—ensuring you attract,
              engage, and hire top talent while reducing time-to-hire and
              cost-per-hire. We offer flexible models that include One-Time
              Placements, Permanent Placements, and Project-Based Placements,
              allowing your business to scale efficiently and focus on its core
              objectives.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurExpertise;
