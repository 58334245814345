import "./copywrite.css";

function CopyWrite() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center copMainDiv">
          <span className="p-4 font-face-OpenSans copywriteTxt">
            © 2024 Quantum Pulse. All rights reserved.
          </span>
        </div>
      </div>
    </>
  );
}

export default CopyWrite;
