import "./design_services.css";

function DesignServices() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center  align-items-center">
          <div className="d-flex flex-column text-center position-relative designSerLeft">
            <div className="desSerYellowDiv">
              <span className="desSerYellowTxt font-face-Corki position-relative">Design</span>
            </div>
            <div className="desSerBlackDiv">
              <span className="desSerBlackTxt font-face-Corki position-relative">
                Services
              </span>
            </div>
          </div>

          <img
            className="designSerImg"
            src="./images/home/design_service.jpg"
            alt="Design Servies"
          ></img>
          <div className="d-flex flex-column text-left font-face-OpenSans  position-relative designSerRight">
            <span className="designSerTxt1">
              In addition to recruitment, Quantum Pulse offers premium Design
              Services, delivering high-quality solutions in PCB Design, Product
              Prototyping, and Embedded Systems.
            </span>
            <span className="designSerTxt2 mt-4">
              Our experienced design team leverages the latest tools and
              technology to bring your ideas to life, from concept to
              implementation, ensuring your product development cycle is fast,
              efficient, and aligned with market demands.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default DesignServices;
