import "./rpsPageInfo.css";

function RpsPageInfomation() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 p-5 rpsPageInofMainBgDiv d-flex justify-content-center">
          <div className="d-flex p-4">
            <img
              className="rpsInfoImg px-4"
              src="./images/rps/CatImg_1.jpg"
              alt="image1"
            ></img>
            <div className="d-flex flex-column rpsPageTxtConttWidth text-start px-4">
              <span className="font-face-Thasadith rpsPagInfoHedTxt mb-4">
                One-Time Placements
              </span>
              <span className="font-face-OpenSans rpsPagInfoDescTxt">
              Our One-Time Placement service is the ideal solution for businesses looking to fill a specific role on a short-term or urgent basis. We focus on thoroughly understanding the job requirements and ensure that the sourced candidate is not only highly skilled but also a strong cultural fit...
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12 p-5 d-flex justify-content-center">
          <div className="d-flex py-4">
            <div className="d-flex flex-column rpsPageTxtConttWidth text-end px-4">
              <span className="font-face-Thasadith rpsPagInfoHedTxt mb-4">
                Permanent Placements
              </span>
              <span className="font-face-OpenSans rpsPagInfoDescTxt">
              Our Permanent Placement service at Quantum Pulse Technologies is designed to help businesses build a strong core team for long-term success. We manage the entire recruitment process, from sourcing and screening to interviewing and negotiating employment offers...
              </span>
            </div>
            <img
              className="rpsInfoImg px-4"
              src="./images/rps/CatImg_2.jpg"
              alt="image2"
            ></img>
          </div>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-12 p-5 rpsPageInofMainBgDiv d-flex justify-content-center">
          <div className="d-flex mt-4">
          <img
              className="rpsInfoImg px-4"
              src="./images/rps/CatImg_3.jpg"
              alt="image1"
            ></img>
            <div className="d-flex flex-column rpsPageTxtConttWidth text-start px-4">
              <span className="font-face-Thasadith rpsPagInfoHedTxt mb-4">
                Project-Based Placements
              </span>
              <span className="font-face-OpenSans rpsPagInfoDescTxt">
                Our Permanent Placement service at Quantum Pulse Technologies is
                designed to help businesses build a strong core team for
                long-term success. We manage the entire recruitment process,
                from sourcing and screening to interviewing and negotiating
                employment offers Our Permanent Placement service at Quantum
                Pulse Technologies is designed to help businesses build a strong
                core team for long-term success. We manage the entire
                recruitment process, from sourcing and screening to interviewing
                and negotiating employment offers...
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RpsPageInfomation;
