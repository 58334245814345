import "./rps.css";
import RpsPageDescription from "./rpsPageDesc/rps_page_desc";
import RpsPageInfomation from "./rpsPageInfo/rpsPageInfo";

function RPS() {
  return (
    <>
      <div className="row g-0 position-relative">
        <div className="col-12">
          <div className="row g-0 position-relative">
            <div className="col-12">
              <img
                className="homeImg"
                src="./images/rps/rps_page.jpg"
                alt="recruitment"
              />
            </div>
          </div>
          <div className="row g-0 position-absolute rpsHedTxtTop mt-4 pt-4">
            <div className="col-4 d-flex justify-content-end pt-xxl-4">
              <div className="d-inline-flex flex-column text-end">
                <span className="font-face-BebasNeue rpsImgTxt">
                  RECRUITMENT
                </span>
                <span className="font-face-BebasNeue rpsImgTxt">PROCESS</span>
                <span className="font-face-BebasNeue rpsImgTxt">
                  OUTSOURCING
                </span>
                <span className="font-face-BebasNeue rpsImgTxt rpsImgTxtBg pt-2 pb-1 px-3">
                  SOLUTIONS
                </span>
              </div>
            </div>
            <div className="col-8"></div>
          </div>
        </div>
      </div>
      <RpsPageDescription />
      <RpsPageInfomation />
    </>
  );
}

export default RPS;
