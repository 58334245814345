import "./contactus.css";

function ContactUs() {
  return (
    <>
      <div className="row g-0 contactUsPageBg">
        <div className="col-12">
          <div className="row m-4 justify-content-center">
            <div className="col-8 d-flex flex-column">
              <span className="font-face-Thasadith contactHedTxt">
                Contact Us
              </span>
              <span className="font-face-OpenSans  contactDecTxt">
                We offer specialized Embedded Systems Development for diverse
                applications. Our team excels in microcontroller programming,
                firmware development, and embedded software solutions. Whether
                your focus is on IoT devices or real-time systems...
              </span>
              <div className="row g-0 font-face-OpenSans my-3">
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">First Name</span>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">Last Name</span>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">Phone number</span>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">Email id</span>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">Location</span>
                  </div>
                </div>
                <div className="col-6 my-2">
                  <div className="contFldBg d-flex align-items-center ">
                    <span className="contFldSpan ps-4">City</span>
                  </div>
                </div>
                <div className="col-12 my-2">
                  <span className="contFldSpan">How can we help you ?</span>
                </div>
                <div className="col-12 my-2">
                  <div className="contCommnetFldBg ps-2 pt-2">
                    <span className="contFldSpan ">
                      Write your commnet......
                    </span>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center my-3">
                  <span className="contUsBtnSpan">CONTACT US</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
