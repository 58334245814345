import "./job_page.css";

function JobDetails() {
  return (
    <>
      <div className="row g-0 jobPageBg p-4">
        <div className="col-2">

        </div>
        <div className="col-8">
        <div className="col-12">
          <span className="font-face-Thasadith jobPageHedTxt">
            Quantum Jobs
          </span>
        </div>
        <div className="col-12">
          <span className="font-face-OpenSans jobPageDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions. Whether your
            focus is on IoT devices or real-time systems...
          </span>
        </div>
        <div className=" col-12 d-flex justify-content-between my-4 ">
          <span className="font-face-OpenSans jobPageNumTxt"> 1 to 20</span>
          <div className="searchBg p-2">
            <span className="font-face-OpenSan searchTxt1">Search :</span>
            <span className="font-face-OpenSansBol searchTxt2"> All Jobs</span>
          </div>
        </div>
        <div className="col-12 p-3 jobDetailBg d-flex  flex-column font-face-OpenSans mb-3">
          <div className="d-flex justify-content-between my-2">
            <span className="jobHeadTxt">Quantum Systems Engineer</span>
            <span className="jobPostDateTxt">5 days ago</span>
          </div>
          <span className="jobDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions.
          </span>
          <div className="d-flex my-2">
            <span className="jobExpTxt">3 - 4 Years</span>
            <span className="jobLocTxt ms-4">Chennai, Tamilnadu</span>
          </div>
        </div>
        <div className="col-12 p-3 jobDetailBg d-flex  flex-column font-face-OpenSans mb-3">
          <div className="d-flex justify-content-between my-2">
            <span className="jobHeadTxt">Quantum Systems Engineer</span>
            <span className="jobPostDateTxt">5 days ago</span>
          </div>
          <span className="jobDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions.
          </span>
          <div className="d-flex my-2">
            <span className="jobExpTxt">3 - 4 Years</span>
            <span className="jobLocTxt ms-4">Chennai, Tamilnadu</span>
          </div>
        </div>
        <div className="col-12 p-3 jobDetailBg d-flex  flex-column font-face-OpenSans mb-3">
          <div className="d-flex justify-content-between my-2">
            <span className="jobHeadTxt">Quantum Systems Engineer</span>
            <span className="jobPostDateTxt">5 days ago</span>
          </div>
          <span className="jobDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions.
          </span>
          <div className="d-flex my-2">
            <span className="jobExpTxt">3 - 4 Years</span>
            <span className="jobLocTxt ms-4">Chennai, Tamilnadu</span>
          </div>
        </div>
        <div className="col-12 p-3 jobDetailBg d-flex  flex-column font-face-OpenSans mb-3">
          <div className="d-flex justify-content-between my-2">
            <span className="jobHeadTxt">Quantum Systems Engineer</span>
            <span className="jobPostDateTxt">5 days ago</span>
          </div>
          <span className="jobDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions.
          </span>
          <div className="d-flex my-2">
            <span className="jobExpTxt">3 - 4 Years</span>
            <span className="jobLocTxt ms-4">Chennai, Tamilnadu</span>
          </div>
        </div>
        <div className="col-12 p-3 jobDetailBg d-flex  flex-column font-face-OpenSans mb-3">
          <div className="d-flex justify-content-between my-2">
            <span className="jobHeadTxt">Quantum Systems Engineer</span>
            <span className="jobPostDateTxt">5 days ago</span>
          </div>
          <span className="jobDescTxt">
            We offer specialized Embedded Systems Development for diverse
            applications. Our team excels in microcontroller programming,
            firmware development, and embedded software solutions.
          </span>
          <div className="d-flex my-2">
            <span className="jobExpTxt">3 - 4 Years</span>
            <span className="jobLocTxt ms-4">Chennai, Tamilnadu</span>
          </div>
        </div>
        <div className="d-flex justify-content-between font-face-OpenSans">
          <span className="jobPreBtnSpan">PREVIOUS</span>
          <span className="jobNextBtnSpan">NEXT</span>
        </div>

        </div>
        <div className="col-2">

        </div>
       
      </div>
    </>
  );
}

export default JobDetails;
