import "./empower.css";

function Empower() {
  return (
    <>
      <div className="row g-0">
        <div className="col-12 d-flex justify-content-center position-relative">
          <img
            className="empowerImg"
            alt="Empowering Growth"
            src="./images/home/empower.jpg"
          ></img>
          <div className="empowerHedMainDiv font-face-Thasadith position-absolute d-flex empHedTopPosi">
            <span className="empoHedTxt1Span d-flex align-content-center pt-2 mt-1">Empowering Growth with</span>
            <span className="empoHedTxt2Span px-3"> Talent & Innovation</span>
          </div>
          <div className=" d-flex justify-content-center  empoBotTxtMainDiv font-face-OpenSans position-absolute">
            <span className="empBotTxtSpan">
              At Quantum Pulse Technologies, we don’t just fill roles or design
              product we partner with you to fuel your growth through strategic
              talent acquisition and innovative design solutions. Let us help
              you build a team and create products that shape the future.
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Empower;
