import "./jobs.css";

function Jobs() {
  return (
    <>
      <div className="row g-0 justify-content-center">
        <div className="col-9 py-3">
          <span className="font-face-Thasadith jobsHedTxt">
            Our Infrastructure
          </span>
        </div>
      </div>
      <div className="row g-0 justify-content-center infraThumRowBg p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra1.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
              ELECTRON DEVICES AND ANALOG ELECTRONIC CIRCUITS LABORATORY
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
              In Electronic Devices and Circuits Laboratory students learn to
              debug, design and build basic electronic circuits. They experiment
              with resistors, capacitors, transistors, integrated circuits,
              master measurement equipment such as digital meters, digital
              storage oscilloscopes and develop practical circuit design skills.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra2.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Programming Languages Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            Programming Languages Laboratory is Wi-Fi enabled and well-established with 70 desktop systems with i5 processors, 16GB DDR3 RAM, and 500GB HDDs along with projectors. The laboratory includes 3 network 24-port switches and one 16-port network switch. The laboratory provides our students with the resources to learn Programming languages (C, C++, Python, Java), Databases, Data Structures, and Algorithms.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra3.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Digital System Design Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            In this lab, students design and build logic circuits using both digital ICs and HDLs (Verilog/VHDL). They learn the design process from concept to realization, employing standard tools like Vivado and ModelSim for simulation and verification. This lab is equipped with Digital IC Trainer Kit, which holds Logic gates, Universal gates which can be used for Flips-Flops, counters, registers, multiplexer and de-multiplexer.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra4.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Microprocessor and Microcontroller Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The Microprocessor and Microcontroller Laboratory aims to provide students with a strong foundation in microcontroller architecture and its programming. The students learn both Assembly Language/Embedded C programming of Microcontrollers. The laboratory has state of art Microprocessor & Microcontroller Trainer kit, simulation tools, evaluation kit, IDEs and other interfacing devices.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra5.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Embedded System and IoT Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            This is a workspace for the students to explore the connection of hardware and software in connected devices.The available software packages include Keil software for ARM processor, C Compiler, Python, RTOS and target boards of Keil, Logic Analyzer, TEXAS code composer studio. The students learn to integrate sensors, actuators, and communication modules with CC3200, MSP430, PIC, Arduino, Raspberry Pi, and specialized development kits.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra6.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Digital Signal Processing Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The DSP Lab features 70 desktop systems equipped with MATLAB and interfaced with DSP trainer kits (TMS320CXXX). The Students will have hands-on learning experiences in various fields of signal processing, implementing both hardware and software experiments.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center infraThumRowBg p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4">
            <img
              className="infraThumsImg"
              src="./images/infra/infra7.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Communication Systems Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The Communication Systems Laboratory emphasizes hands-on use of equipment such as spectrum analyzer, oscilloscopes, and signal generators to analyze and design communication systems. Students engage in practical experiments that involve simulating and implementing modulation schemes like AM, FM, and digital modulation techniques such as QAM, GMSK, QPSK, and OFDM.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra8.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            VLSI Design Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            In this lab, the students focus on creating complex integrated circuits from digital logic to analog circuits. The lab is equipped with industry-leading EDA tools such as Cadence design systems, Vivado and EDA playground to simulate, and synthesize. The Hardware available in the lab for implementation is Xilinx/Altera based FPGA and Zync series SoC boards. Practical exercises include designing logic gates, memory elements, and complex digital systems, by considering power consumption, timing constraints, and physical layout.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra9.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Computer Networks and Security Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            Computer Networks and Security Laboratory engage the students to understand the working of protocols at various layers of network architecture and to design, implement, and troubleshoot the computer networks and learn about security. They configure network devices, set up LANs and WANs, and explore protocols such as TCP/IP, DNS, and DHCP and simulate using Wireshark, Packet tracer, etc. This laboratory also provides students with a practical experience with artificial intelligence, machine learning, and data science concepts.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra10.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Microwave and Optical communication Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The students explore technologies related to high-frequency electromagnetic waves and optical signals. They work with equipment such as microwave benches, signal generators, spectrum analyzers, and optical components like lasers, fibers, and photodetectors. Students learn to analyze signal propagation characteristics, evaluate system performance metrics like bandwidth and signal-to-noise ratio, and optimize communication links for various applications.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra11.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Digital Signal Processing Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The DSP Lab is established with 62 desktop systems equipped with MATLAB (30 user licenses) and interfaced with DSP trainer kits (TMS320CXXX). It offers students hands-on learning experiences in various fields of signal processing, implementing both hardware and software experiments.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra12.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Communication Systems Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The Communication Systems Laboratory emphasizes hands-on use of equipment such as spectrum analyzers, oscilloscopes, and signal generators to analyze and design communication systems. Students engage in practical experiments that involve simulating and implementing modulation schemes like AM, FM, and digital modulation techniques such as QAM, GMSK, QPSK, and OFDM
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center infraThumRowBg p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra13.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Digital System Design and Microprocessor Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            This Laboratory provides students with practical experience in designing, analyzing, and implementing digital circuits and microprocessor-based systems. This laboratory is equipped with Digital IC Trainer Kit, which holds Logic gates, Universal gates which can be used for Flips-Flops, counters, registers, multiplexer and de-multiplexer. Here, students design and build logic circuits using both digital ICs and HDLs (Verilog/VHDL). The laboratory also has state of art Microprocessor & Microcontroller Trainer kit, simulation tools, evaluation kit, IDEs and other interfacing devices.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra14.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Computer Networks and Security Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            Computer Networks and Security Laboratory engage the students to understand the working of protocols at various layers of network architecture and to design, implement, and troubleshoot the computer networks and learn about security. They configure network devices, set up LANs and WANs, and explore protocols such as TCP/IP, DNS, and DHCP and simulate using Wireshark, Packet tracer, etc. This laboratory also provides students with a practical experience with artificial intelligence, machine learning, and data science concepts.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra15.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Manufacturing Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The laboratory is equipped with Capstan Lathe, Turret Lathe, Centre Lathes, Milling, Shaping, Slotting, Grinding, Hobbing, Tool and Cutter Grinder, Turning and Milling Dynamometer. This lab provides hands-on learning and practical exposure in different manufacturing operations.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra16.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Advanced Material Processing and Testing Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The DST-FIST sponsored laboratory equipped with Planetary Ball Mill, Particle Size Analyzer, Hydraulic Press, Universal, Impact, Torsion, Hardness testing machines, Double disc polishing machine, and Metallurgical microscopes. This lab provides students with skills in testing, analysis of strength and behaviour of materials under different load conditions of various engineering materials.s.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra17.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Thermal Engineering Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The lab is equipped with Single Cylinder Four Stroke Diesel Engine with Mechanical Loading, Multi Cylinder Petrol Engine, Steam Boiler, Natural and Forced Convection, Pin Fin, Stefan Boltzmann, Lagged Pipe Apparatus, Parallel and Counter Flow Heat Exchanger, R&AC. This lab facilitates evaluation of thermal conductivity, heat transfer coefficient of materials, and effectiveness of heat exchanger.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra18.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Fluid Mechanics and Machinery Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The lab is well established with Venturimeter, Orificemeter, Rotameter, Centrifugal, Reciprocating, Gear, and Submersible Pumps, Pelton, Francis and Kaplan Turbines. The lab provides experiential learning to the students in flow measurement characteristics of various pumps and turbines.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center infraThumRowBg p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra19.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Computer Aided Design & Engineering and Programming Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The laboratory has 72 Dell Desktop Computers with core i3 processor with 8 GB RAM, 1TB HDD, 1 Projector, and Network Switch 24 port x 7 with 50 Mbps wifi facility to support students in practical learning. The lab is equipped with SolidWorks 500 users, PTC Creo (Design, Manufacturing & Simulation) with 50 users, open source AutoCAD, and CATIA with 1 commercial licence. This laboratory supports the students to up skill with CAD modelling, GD&T, Static and Dynamic Analysis of systems. This laboratory provides hands-on learning. This laboratory also facilitates the students to gain expertise in programming languages (C, Java, Python).
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra20.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Computer Aided Manufacturing Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The laboratory is equipped with Fanuc Controlled CNC Turning Centre, CNC Machining Centre with 6 Station, Dell Desktop with core i3 processor with 8 GB RAM, 1TB HDD, with 50 Mbps WIFI facility to support students in practical learning. This lab imparts practical learning in Tool Path Simulation and its operations in CNC Machines.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra21.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Industrial IoT and Robotics Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The laboratory is facilitated with RV2SBQ 6 Axis Industrial Robotic Arm, 12 Dell Desktop Computer 500 GB HDD, 4 GB Ram, Electro Pneumatic & Hydraulic Kit, 8051 Microcontroller trainer kit, Speed control drives for AC & DC, Servo Controller Interfacing for DC Motor and PID Controller based AC and DC Drives. It equips students with skills in microcontroller programming, sensor interfacing, automation and data communication for creating interconnected systems.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra22.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Metrology and Measurements laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            This laboratory is equipped with Floating Carriage Micrometer, Auto-collimator, Profile Projector, Auto-collimator, Displacement and Temperature measurement setup, Force and Torque Measurement Setup, Governor, Gyroscope, Spring Mass System, Balancing of Masses, Frequency Measurements. This facility provides hands on practical learning in assessing dimensional accuracy, surface roughness of geometric parameters, and characteristics studies of systems.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra23.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Kinematics and Dynamics Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The lab features Kinematic Mechanisms, Governors, Gyroscopes, and Spring Mass Systems, equipment for Balancing Rotating and Reciprocating Masses, as well as systems for both damped and undamped vibrations and Frequency Measurements. This facility provides insights into kinematic linkages and constrained motions, the mass moment of inertia of mechanical components, and the balancing of static and dynamic forces.
            </span>
          </div>
        </div>
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra24.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Laser CuPractices Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The lab is equipped with Fused Deposition Modelling (FDM) Printers, Selective Laser Sintering (SLS) Printer, Filament dryer Box and 3D Scanner, pipeline fittings and R&AC test rig. It is designed to offer hands-on training and workshops for students, equipping them with practical skills in plumbing, 3D printing, and AC troubleshooting.
            </span>
          </div>
        </div>
      </div>
      <div className="row g-0 justify-content-center infraThumRowBg p-4">
        <div className="col-3">
          <div className="infraThumsMainDiv d-flex flex-column m-4 ">
            <img
              className="infraThumsImg"
              src="./images/infra/infra25.png"
              alt="thums infra"
            />
            <span className="font-face-Thasadith infraThumHedTxt py-4">
            Artificial Intelligence and Data Analysis Laboratory
            </span>
            <span className="font-face-OpenSans infraThumDescTxt">
            The lab has 72 Dell Desktop Computers with core i3 processor with 8 GB RAM, 1TB HDD, 1 Projector, and Network Switch 24 port x 7 with 50 Mbps wifi facility to support students in practical learning. The lab facilitates the students to perform Data Analysis and work on Visualisation Projects.
            </span>
          </div>
        </div>    
        <div className="col-3"></div>  
        <div className="col-3"></div>  
      </div>
      
    </>
  );
}

export default Jobs;
