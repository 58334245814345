import "./infrastructure.css";
import Jobs from "./jobs/jobs";

function Infrastructure() {
  return (
    <>
      <div className="row g-0 position-relative">
        <div className="col-12">
          <div className="row g-0 position-relative">
            <div className="col-12 d-block position-relative">
              <img
                className="infraImg"
                src="./images/infra/infra_main.jpg"
                alt="Infrastructure"
              />
            </div>
          </div>
          <div className="row g-0  position-absolute infraTxtTop">
            <div className="col-xxl-12 p-xxl-3"></div>
            <div className="col-7 pt-4"></div>
            <div className="col-5 pt-4">
              <div className="d-inline-flex flex-column pt-xxl-4">
                <span className="font-face-BebasNeue infraImgTxt1 text-center">
                  OUR
                </span>
                <span className="font-face-BebasNeue infraImgTxt2 text-center">
                  INFRASTRUCTURE
                </span>
                <span className="font-face-OpenSans infraImgTxt3 p-3">
                  Our Permanent Placement service at Quantum Pulse Technologies
                  is designed to help businesses build a strong core team for
                  long-term success. We manage the entire recruitment process,
                  from sourcing and screening to interviewing and negotiating
                  employment offers Our Permanent
                </span>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Jobs />
    </>
  );
}

export default Infrastructure;
